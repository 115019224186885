<template>
  <div class="page">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-account</v-icon>
      أرصدة العملاء
      <back-button />
    </h2>
    <hr class="my-5 header-divider" />
    <!-- filter -->
    <!-- <filters-box /> -->
    <filters-box @active="handleGetAllActive"/>
    <v-chip class="mb-2" label  color="secondary">
      <v-icon left>
        mdi-account-circle-outline
      </v-icon>
      عدد المستخدمين {{totalUsers}}
    </v-chip>
    <!-- TABLE -->
    <v-data-table
        item-key="id"
        class="elevation-1"
        :loading="table.loading"
        :headers="table.headers"
        :items="records"
        loading-text="Downloading Data"
        no-data-text="No Data"
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.free_balance }}</td>
          <td>{{ item.paid_balance }}</td>
          <td>{{ item.balance }}</td>
          <!-- Add button in this cell -->
          <td>
            <v-btn color="primary" text @click="redirectToTransactions(item.id)">
              عرض عمليات المحفظة
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- PAGINATION -->
    <!-- "totalPages" -->
    <div class="text-center mt-7">
      <v-pagination
          total-visible="7"
          circle
          :value="+getFilter.page"
          :length="Math.ceil(total / 10)"
          @input="changePage"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import RecordForm from "./RecordForm";
import {mapState, mapMutations, mapGetters, mapActions} from "vuex";
import FiltersBox from "./FilterBox.vue";

export default {
  name: "walletBalances",
  components: { RecordForm, FiltersBox },

  data: function() {
    return {
      activeStatus:0,
      table: {
        headers: [
          {
            value: "name",
            text: "الاسم"
          },
          {
            value: "phone",
            text: "الهاتف"
          },

          {
            value: "free_balance",
            text: "الرصيد المجاني"
          },
          {
            value: "paid_balance",
            text: "الرصيد المدفوع"
          },
          {
            value: "balance",
            text: "إجمالي الرصيد"
          },


        ],
        loading: false
      }
    };
  },

  computed: {
    ...mapState("walletBalances", ["records", "filters", "total","totalUsers"]),
    ...mapGetters("walletBalances" , ["getFilter"])
  },

  watch: {
    $route: {
      async handler(route) {
        // console.log(route.query)
        if (!route.query.page) {
          await this.$router.replace({ query: { ...route.query, page: 1 } });
          return;
        }
        this.SET_FILTERS({...this.filters,...route.query});
        this.fetchRecords();

      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    handleGetAllActive(val){
      this.activeStatus = val
      return val;
    },
    ...mapMutations("walletBalances", [
      "SHOW_RECORD_FORM",
      "SET_EDIT_MODE",
      "SET_RECORD_FORM",
      "SET_FILTERS"
    ]),
    ...mapActions("walletBalances", ["fetchBalancesRecords"]),

    changePage(val) {
      // console.log(val)
      // console.log(this.activeStatus)
      this.$router
          .replace({
            query: { ...this.$route.query, page: val},
          })
          .catch(() => {});
    },
    fetchRecords(active) {
      this.table.loading = true;
      this.$store
          .dispatch("walletBalances/fetchBalancesRecords",active)
          .catch(error => {
            this.$bus.$emit("showSnackbar", {
              text: "An error occurred when rendering the data.",
              color: "red darken-3"
            });
          })
          .finally(() => {
            this.table.loading = false;
          });
    },

    redirectToTransactions(userId) {
      // Redirect to admin/walletTransactions with the specific userId
      this.$router.push({ path: '/wallet_transactions', query: { userId: userId } });
    }
  }
};
</script>
